ul.social-links{
	margin-bottom: 0;
	li{
		&:first-child{
			a{
				padding-left: 0;
			}
		}
		a{
			padding: 0 15px;
			display: block;
			i{
				font-size: 20px;
				color: $black;
			}
		}
	}
}