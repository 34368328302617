// Google Font Import
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700|Roboto:300,400,500,700');
// font size
html {
	font-size: 16px;
}
// Body
body{
	font-family: $primary-font;
	-webkit-font-smoothing: antialiased;
}

// Headings
h1,h2,h3,h4,h5,h6{
	font-family: $secondary-font;
	color: $black;
	font-weight: 600;
}

h1{
	font-size: 2.8rem; //45px
}

h2{
	font-size: 2.5rem; //40px
}

h3{
	font-size: 2.25rem; //36px
}

h4{
	font-size: 1.6875rem;//27px
}

h5{
	font-size: 1.375rem; //22px	
}

h6{
	font-size: 1.25rem; //20px
}
// Others 
p, a, li, blockquote, label{
	font-size: 1rem;
	line-height: 26px;
	color: $text-color;
	margin-bottom: 0;
}

// Placeholder Color Change
.form-control::-webkit-input-placeholder { 
    color: darken($light, 30%);
    font-size: 0.9375rem;//15px
 }